import $ from '../../../node_modules/jquery/dist/jquery.js';
import 'easing';
import { Common } from "./common";
var aoyagi;
(function (aoyagi_1) {
    var Main = /** @class */ (function () {
        function Main() {
            //ブレイクポイント
            this.breakPoint = 768;
            this.common = new Common();
            this.scrollTarget = [
                { tgt: 'h1', do: false },
                { tgt: '#menu ul li.menu01', do: false },
                { tgt: '#menu ul li.menu02', do: false },
                { tgt: '#menu ul li.menu03', do: false },
                { tgt: '#menu ul li.menu04', do: false },
                { tgt: '#menu ul li.sns .icon_tw', do: false },
                { tgt: '#menu ul li.sns .icon_fb', do: false },
                { tgt: '#menu ul li.sns .icon_ln', do: false },
                { tgt: '#header .to_map', do: false },
                { tgt: '.slide_area', do: false },
                { tgt: '.course_menu h3', do: false },
                { tgt: '.course_menu ul .menu01', do: false },
                { tgt: '.course_menu ul .menu02', do: false },
                { tgt: '.course_menu ul .menu03', do: false },
                { tgt: '.course_menu ul .menu04', do: false },
                { tgt: '.course_menu ul .menu05', do: false },
                { tgt: '.course_menu ul .menu06', do: false },
                { tgt: '.course_menu ul .menu07', do: false },
                { tgt: '.course_menu ul .menu08', do: false },
                { tgt: '.course_menu ul .menu09', do: false },
                { tgt: '.course_menu .to_map', do: false }
            ];
            this.init();
        }
        Main.prototype.init = function () {
            var _this = this;
            $('.to_map a').on('click', function (event) {
                var posX = $('#map_wrpr').offset().top;
                console.log(posX);
                $('html,body').animate({ scrollTop: posX + 'px' }, 500, 'easeOutExpo');
                (event.preventDefault) ? event.preventDefault() : event.returnValue = false;
                return false;
            });
            $(window).on('resize', function () { _this.resize(); });
            this.resize();
        };
        Main.prototype.load = function () {
            var _this = this;
            this.slideStart();
            $(window).on('scroll', function () { _this.scroll(); });
            this.scroll();
        };
        Main.prototype.slideStart = function () {
            var _this = this;
            this.slideID = setInterval(function () { _this.slideNext(); }, 5000);
        };
        Main.prototype.slideNext = function () {
            this.slideNextPc();
            this.slideNextSp();
        };
        Main.prototype.slideNextPc = function () {
            var crnt = -1;
            var old = -1;
            $('.slide_area ul.pc li').each(function (i, o) {
                if ($(o).hasClass('crnt')) {
                    crnt = i;
                }
            });
            old = crnt;
            crnt = (crnt + 1 >= $('.slide_area ul.pc li').length) ? 0 : crnt + 1;
            $('.slide_area ul.pc li').eq(old).stop(true, false)
                .css({ opacity: 1, left: '0px' })
                .animate({ opacity: 0, left: '-50px' }, 1000, 'easeInExpo');
            $('.slide_area ul.pc li').eq(crnt).stop(true, false)
                .delay(1000)
                .css({ opacity: 0, left: '50px' })
                .animate({ opacity: 1, left: '0px' }, 1000, 'easeOutExpo');
            $('.slide_area ul.pc li').removeClass('crnt');
            $('.slide_area ul.pc li').eq(crnt).addClass('crnt');
        };
        Main.prototype.slideNextSp = function () {
            var crnt = -1;
            var old = -1;
            $('.slide_area ul.sp li').each(function (i, o) {
                if ($(o).hasClass('crnt')) {
                    crnt = i;
                }
            });
            old = crnt;
            crnt = (crnt + 1 >= $('.slide_area ul.sp li').length) ? 0 : crnt + 1;
            $('.slide_area ul.sp li').eq(old).stop(true, false)
                .css({ opacity: 1, left: '0px' })
                .animate({ opacity: 0, left: '-50px' }, 1000, 'easeInExpo');
            $('.slide_area ul.sp li').eq(crnt).stop(true, false)
                .delay(1000)
                .css({ opacity: 0, left: '50px' })
                .animate({ opacity: 1, left: '0px' }, 1000, 'easeOutExpo');
            $('.slide_area ul.sp li').removeClass('crnt');
            $('.slide_area ul.sp li').eq(crnt).addClass('crnt');
        };
        Main.prototype.initMap = function () {
            //var aoyagi = {lat: 35.6339778, lng: 139.6864937};
            var aoyagi = { lat: 35.6339723, lng: 139.6865159 };
            var map = new google.maps.Map(document.getElementById('map'), {
                zoom: 19,
                center: aoyagi,
                styles: [{
                        "stylers": [
                            { "saturation": -100 }
                        ]
                    }]
            });
            var image = {
                url: 'http://aoyagi.tokyo/mark.png',
                scaledSize: new google.maps.Size(80, 80)
            };
            var marker = new google.maps.Marker({
                position: aoyagi,
                map: map,
                icon: image
            });
            //
            var directionsDisplay = new google.maps.DirectionsRenderer({
                suppressMarkers: true
            });
            directionsDisplay.setMap(map);
            var directionsService = new google.maps.DirectionsService();
            var request = {
                origin: aoyagi,
                destination: { lat: 35.6287622, lng: 139.6852706 },
                travelMode: google.maps.DirectionsTravelMode.WALKING
            };
            directionsService.route(request, function (response, status) {
                if (status == google.maps.DirectionsStatus.OK) {
                    directionsDisplay.setDirections(response);
                }
            });
        };
        Main.prototype.resize = function () {
            var hig = $(window).innerHeight();
            //var hig = window.innerHeight;
            var wid = $(window).innerWidth();
            if (wid > 750) {
                $('#header').css({ height: (hig - 98) + 'px' });
                $('#map_wrpr').css({ height: hig + 'px' });
            }
            else {
                var hdrHig = hig - (wid * 0.229);
                $('#header').css({ height: hdrHig + 'px' });
            }
        };
        Main.prototype.scroll = function () {
            var scTop = $(window).scrollTop();
            var hig = $(window).innerHeight();
            console.log(hig);
            //var winH = scTop + hig*0.75;
            var winH = scTop + hig * 1.0;
            for (var i = 0; i < this.scrollTarget.length; i++) {
                if (this.scrollTarget[i].do == false) {
                    var _tgt = $(this.scrollTarget[i].tgt);
                    if (_tgt.length > 0) {
                        var t = _tgt.offset().top;
                        if (winH > t && this.scrollTarget[i].do == false) {
                            this.scrollTarget[i].do = true;
                            _tgt.addClass('show');
                        }
                    }
                }
            }
        };
        return Main;
    }());
    aoyagi_1.Main = Main;
})(aoyagi || (aoyagi = {}));
(function () {
    var _aoyagi;
    document.addEventListener("DOMContentLoaded", function (event) {
        _aoyagi = new aoyagi.Main();
        var win = window;
        win.aoyagi = _aoyagi;
    });
    window.onload = function () {
        _aoyagi.load();
    };
})();
