import $ from '../../../node_modules/jquery/dist/jquery.js';
var Common = /** @class */ (function () {
    function Common() {
        this.isSp = false;
        //
        this.IS_FIREFOX = undefined;
        this.IE_VER = -1;
        this.IS_IE = false;
    }
    Common.prototype.init = function () {
        var _this = this;
        this.detectSp();
        this.detectBrowser();
        $('.hoverAlpha').hover(function () {
            if (_this.IS_IE == false || (_this.IS_IE == true && _this.IE_VER > 8))
                $(_this).stop().animate({ 'opacity': 0.5 }, 50, 'linear');
        }, function () {
            if (this.IS_IE == false || (this.IS_IE == true && this.IE_VER > 8))
                $(this).stop().animate({ 'opacity': 1.0 }, 500, 'linear');
        });
        $(window).scroll(function () {
            _this.scroll();
        });
        this.scroll();
    };
    Common.prototype.onLoad = function () { };
    Common.prototype.detectSp = function () {
        if (navigator.userAgent.indexOf('iPhone') > 0 || navigator.userAgent.indexOf('iPad') > 0 ||
            navigator.userAgent.indexOf('iPod') > 0 ||
            navigator.userAgent.indexOf('Android') > 0 /* || navigator.userAgent.indexOf('iPad') > 0*/) {
            this.isSp = true;
        }
    };
    Common.prototype.scroll = function () {
        var hPos = $(window).scrollTop();
    };
    //表示領域の横幅を取得
    Common.prototype.getWid = function () {
        return (this.isSp) ? window.innerWidth : $(window).width();
    };
    //表示領域の高さを取得
    Common.prototype.getHig = function () {
        return (this.isSp) ? window.innerHeight : $(window).height();
    };
    //ドキュメントの横幅を取得
    Common.prototype.getDocWid = function () {
        return $(document).width();
    };
    //ドキュメントの高さを取得
    Common.prototype.getDocHig = function () {
        return $(document).height();
    };
    //サニタイズ
    Common.prototype.encode = function (str) {
        return str.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;').replace(/'/g, '&#39;');
    };
    Common.prototype.detectBrowser = function () {
        var ua = navigator.userAgent.toLowerCase();
        var appVersion = navigator.appVersion.toLowerCase();
        if (ua.indexOf("firefox") != -1) {
            this.IS_FIREFOX = true;
        }
        else if (ua.indexOf("msie") != -1) {
            this.IS_IE = true;
            if (appVersion.indexOf("msie 6.") != -1) {
                this.IE_VER = 6;
            }
            else if (appVersion.indexOf("msie 7.") != -1) {
                this.IE_VER = 7;
            }
            else if (appVersion.indexOf("msie 8.") != -1) {
                this.IE_VER = 8;
            }
            else if (appVersion.indexOf("msie 9.") != -1) {
                this.IE_VER = 9;
            }
            else if (appVersion.indexOf("msie 10.") != -1) {
                this.IE_VER = 10;
            }
        }
        else if (ua.indexOf("windows") != -1 && ua.indexOf("chrome") == -1 && ua.indexOf("safari") == -1) {
            //IE 11から
            this.IS_IE = true;
            var str = ua.substring(ua.indexOf('(') + 1, ua.indexOf(')'));
            var spStr = str.split(' ');
            var verN = 0;
            for (var i = 0; i < spStr.length; i++) {
                if (spStr[i].indexOf('rv:') != -1) {
                    verN = Number(spStr[i].replace('rv:', ''));
                    break;
                }
            }
            if (verN == 11) {
                this.IE_VER = 11;
            }
        }
    };
    return Common;
}());
export { Common };
